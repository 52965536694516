@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');


html {
  width:100%;
  height:100%;
  background-color: #2b2b2b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  background-color: #2b2b2b;
  text-rendering: optimizeLegibility;
  color:#e4e7eb;
  overflow:hidden;
}

#root {
  height:100%;
  width:100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing:border-box;
}

body, input, textarea, button, select {
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

